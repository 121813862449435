import React from 'react';
import './Reload.less';

// https://www.flaticon.com/free-icon/reload_93641
import { ReactComponent as ReloadIcon } from './reload-icon.svg';

const Reload = () => (
  <div id="reload-overlay">
    <button id="reload-button" type="button" onClick={() => window.location.reload()}>
      <ReloadIcon id="reload-icon" />
    </button>
  </div>
);

export default Reload;
