import React from 'react';
import GarageProjectLogo from '../GarageProjectLogo/GarageProjectLogo';
import './ThankYou.less';

const ThankYou = () => (
  <div id="thank-you-container">
    <h1>THANK YOU!</h1>
    <div id="logo-container">
      <GarageProjectLogo />
    </div>
  </div>
);

ThankYou.propTypes = {
};

export default ThankYou;
