import React from 'react';
import PropTypes from 'prop-types';
import './ThemeSwitcher.less';

// https://www.flaticon.com/free-icon/theme_3659789
import { ReactComponent as ThemeIcon } from './theme-icon.svg';

const ThemeSwitcher = ({ onClick }) => (
  <div id="theme-switcher-overlay">
    <button id="theme-switcher-button" type="button" onClick={onClick}>
      <ThemeIcon id="theme-icon" />
    </button>
  </div>
);

ThemeSwitcher.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ThemeSwitcher;
