import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SignUpForm.less';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import Checkbox from '../Checkbox/Checkbox';
import TextField from '../TextField/TextField';

const BEER_PREFERENCES = [
  {
    id: 'hoppy',
    label: 'Hoppy',
  },
  {
    id: 'lager',
    label: 'Lager',
  },
  {
    id: 'sour',
    label: 'Sour',
  },
  {
    id: 'dark',
    label: 'Dark',
  },
  {
    id: 'hazy',
    label: 'Hazy',
  },
  {
    id: 'dirty-water',
    label: 'Dirty Water',
  },
  {
    id: 'crushed',
    label: 'Crushed',
  },
  {
    id: 'low-alc',
    label: 'Low Alc',
  },
  {
    id: 'crazy-shit',
    label: 'Crazy Shit',
  },
];

const baseUrl = process.env.REACT_APP_NODE_ENV === 'development' ? (
  'http://localhost:3000') : (
  'https://api.gpmatrix.co.nz'
);

const DEFAULTS = {
  personalDetails: {
    firstName: '',
    lastName: '',
    email: '',
  },
  beerPreferences: [],
};

let timeout;

const SignUpForm = ({
  submitting, error, setSubmitting, setError, setSuccess,
}) => {
  const [personalDetails, setPersonalDetails] = useState(DEFAULTS.personalDetails);
  const [beerPreferences, setBeerPreferences] = useState(DEFAULTS.beerPreferences);

  const { firstName, lastName, email } = personalDetails;

  // Clear the fields if populated and no activity after 1 minute.
  useEffect(() => {
    if (personalDetails !== DEFAULTS.personalDetails
      || beerPreferences !== DEFAULTS.beerPreferences) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setPersonalDetails(DEFAULTS.personalDetails);
        setBeerPreferences(DEFAULTS.beerPreferences);
      }, 60000);
      return () => clearTimeout(timeout);
    }
    return null;
  }, [personalDetails, beerPreferences]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const properties = {
      $first_name: firstName,
      $last_name: lastName,
    };
    const data = {
      email,
      // Only append beer preferences if some have been selected.
      properties: beerPreferences.length > 0 ? {
        ...properties,
        'Beer Preferences': beerPreferences,
      } : properties,
    };

    setError(false);
    setSubmitting(true);

    axios.post(`${baseUrl}/klaviyo/profiles`, data)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          // Wait until the transition has completed before clearing fields.
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            setPersonalDetails(DEFAULTS.personalDetails);
            setBeerPreferences(DEFAULTS.beerPreferences);
          }, 1000);
        } else {
          setError(true);
          setSuccess(false);
        }
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleBeerPreferenceChange = (optionId) => {
    setBeerPreferences(beerPreferences.includes(optionId) ? (
      beerPreferences.filter((id) => id !== optionId)
    ) : [...beerPreferences, optionId]);
  };

  return (
    <>
      <h1>
        STAY IN TOUCH!
      </h1>
      <h2>
        SIGN UP FOR EXCLUSIVE NEWS & OFFERS
      </h2>

      <form className="form" onSubmit={handleSubmit}>

        <div id="text-fields-container">
          <TextField
            id="first-name-field"
            value={firstName}
            onChange={(event) => (
              setPersonalDetails((current) => ({ ...current, firstName: event.target.value }))
            )}
            type="text"
            placeholder="First name"
            disabled={submitting}
          />
          <TextField
            id="last-name-field"
            value={lastName}
            onChange={(event) => (
              setPersonalDetails((current) => ({ ...current, lastName: event.target.value }))
            )}
            type="text"
            placeholder="Last name"
            disabled={submitting}
          />
        </div>

        <div id="text-fields-container">
          <TextField
            value={email}
            onChange={(event) => (
              setPersonalDetails((current) => ({ ...current, email: event.target.value }))
            )}
            type="email"
            placeholder="Your email"
            disabled={submitting}
          />
        </div>

        <h2 id="beer-preferences-title">
          BEER PREFERENCES
        </h2>
        <div id="beer-preference-options">
          {BEER_PREFERENCES.map((option) => (
            <Checkbox
              key={option.id}
              id={option.label}
              label={option.label}
              checked={beerPreferences.includes(option.label)}
              onChange={() => handleBeerPreferenceChange(option.label)}
              disabled={submitting}
            />
          ))}
        </div>

        <div id="submit-button-container">
          <button className="button" id="submit-button" type="submit" disabled={submitting}>
            Sign up
          </button>

          <div id="submit-button-message-container">
            <div id="loading-indicator" className={submitting && !error ? 'visible' : 'hidden'}>
              <ClipLoader loading size={20} />
            </div>
            <div id="error-message" className={!submitting && error ? 'visible' : 'hidden'}>
              There was an issue submitting your details
            </div>
          </div>
        </div>

      </form>
    </>
  );
};

SignUpForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
};

export default SignUpForm;
