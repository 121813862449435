import React, { useState, useEffect } from 'react';
import './Layout.less';
import ThemeSwitcher from './components/ThemeSwitcher/ThemeSwitcher';
import SignUpForm from './components/SignUpForm/SignUpForm';
import Reload from './components/Reload/Reload';
import ThankYou from './components/ThankYou/ThankYou';

const TOTAL_THEMES = 4;

let timeout;

const Layout = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [theme, setTheme] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const changeTheme = () => {
    setTheme((current) => setTheme(current === TOTAL_THEMES ? 1 : current + 1));
  };

  useEffect(() => {
    if (!firstLoad) {
      if (!success) {
        changeTheme();
      }
    } else {
      setFirstLoad(false);
    }
  }, [success]);

  // Only show success or error for a few seconds.
  useEffect(() => {
    if (error || success) {
      // Handle success after an error.
      if (error && success) {
        setError(false);
      }
      // Clear existing timeout if one exists.
      if (timeout) {
        clearTimeout(timeout);
      }
      // Set the new timeout to clear the success or error.
      timeout = setTimeout(success ? (() => {
        setSuccess(false);
      }) : (() => {
        setError(false);
      }), 5000);
    }
  }, [error, success]);

  return (
    <div id="main-container" className={`theme-${theme}`}>

      <div
        id="sign-up-transition"
        className={`transition-content ${success ? 'hidden' : 'visible'}`}
      >
        <SignUpForm
          submitting={submitting}
          error={error}
          setSubmitting={setSubmitting}
          setError={setError}
          setSuccess={setSuccess}
        />
      </div>

      <div
        id="thank-you-transition"
        className={`transition-content ${success ? 'visible fade' : 'hidden no-transition'}`}
      >
        <ThankYou />
      </div>

      <Reload />
      <ThemeSwitcher onClick={changeTheme} />
    </div>
  );
};

export default Layout;
