import React from 'react';
import PropTypes from 'prop-types';
import './TextField.less';

const TextField = ({
  id, value, onChange, type, placeholder,
}) => (
  <input
    id={id}
    className="text-field"
    type={type}
    required
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

TextField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

TextField.defaultProps = {
  id: null,
};

export default TextField;
