import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.less';

const Checkbox = ({
  id, label, checked, onChange,
}) => (
  <label key={id} className="checkbox-label checkbox bounce" htmlFor={id}>
    <input
      className="checkbox-input"
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
    />
    <svg viewBox="0 0 21 21">
      <polyline points="5 10.75 8.5 14.25 16 6" />
    </svg>
    <span className="checkbox-label-text">{label}</span>
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
